<template>
  <div class="fill-height py-3">
    <v-container>
      <v-row>
        <v-col class="col-lg-6 col-12">
          <div class="facebook-responsive grey darken-2">
            <div
              class="fb-video"
              :data-href="lastLive.streaming_url"
              data-autoplay="true"
              data-width="1000"
              data-show-text="false"
              data-lazy="true"
              data-allowfullscreen="false"
              target="_top"
            ></div>
          </div>
        </v-col>
        <v-col class="col-lg-6">
          <v-flex :fill-height="true" class="text-center mt-5">
            <div class="text-lg-h1 text-h4 shrink mb-5 ">LIVE<br />Shopping</div>
            <v-btn class="grad-btn white--text" elevation="2" outlined x-large @click="onClickVideo()">SHOP NOW</v-btn>
          </v-flex>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="col-lg-3 col-12"
          v-for="(item, i) in liveHistory"
          :key="i">
          <PreviewPanel :liveSource="item"></PreviewPanel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PreviewPanel from '../../components/ShoppingLive/PreviewPanel'
export default {
  name: 'LiveHistory',
  components: {
    PreviewPanel
  },
  computed: {
    ...mapState({
      liveHistory(state) {
        return state.Live.liveHistory
      },
      lastLive(state) {
        return state.Live.liveHistory[0]
      }
    })
  },
  methods: {
    rernderFB() {
      this.$loadScript(
        'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v5.0&appId=***&autoLogAppEvents=1'
      )
        .then(() => {
          this.$nextTick(() => {
            window.FB.XFBML.parse()
          })
        })
        .catch(() => {
          console.log('facebook embed loading error')
        })
    },
    onClickVideo() {
      this.$store.commit('Live/setLiveSource', this.lastLive)
      this.$router.push({ name: 'Live' })
    },
  },
  async created() {
    console.log('length',this.liveHistory)
    await this.$store.dispatch('Live/onGetAllLiveHistory')
    this.rernderFB()
  }
}
</script>


<style>
</style>