<template>
  <v-card outlined elevation="2" class="pointer" @click="onClickVideo()">
    <v-layout>
      <v-card-text>
        <strong>Live :</strong>  {{ liveSource.name }}<br />
        <strong>Started Date :</strong>  {{ onChangeDate(liveSource.start_live_at) }}
      </v-card-text>
      <v-card-actions>
        <v-btn
        color="primary"
        @click="onClickVideo()"
        >
        WATCH
      </v-btn>
      </v-card-actions>
    </v-layout>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PreviewPanel',
  props: {
    liveSource: {
      type: Object
    }
  },
  methods: {
    onClickVideo() {
      this.$store.commit('Live/setLiveSource', this.liveSource)
      this.$router.push({ name: 'Live' })
    },
    onChangeDate(date){
    return moment(date).format("DD MMM YYYY")
    }
  }
}
</script>
<style>
</style>